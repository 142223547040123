import Vue from 'vue';
import VueRouter from 'vue-router';

import FilmStrip from '../components/FilmStrip.vue';
import TemplateEdit from '../components/TemplateEdit.vue';
import CurriculumLayout from '../components/CurriculumLayout.vue';
import TreeView from '../components/TreeView.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/film-strip',
    name: 'FilmStrip',
    component: FilmStrip,
  },
  {
    path: '/template-edit',
    name: 'TempalteEdit',
    component: TemplateEdit,
  },
  {
    path: '/tree-view',
    name: 'TreeView',
    component: TreeView
  },
  {
    path: '/curriculum-layout',
    name: 'CurriculumLayout',
    component: CurriculumLayout,
  }
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.VUE_APP_ROOT,
  routes,
});

export default router;