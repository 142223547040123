<template>
  <div>
    <b-row>
      <b-col>
        <b-row v-for="(row, index) in curriculumGrid" :key="index">
          <b-col v-for="col in row" :key="col.id" :cols="columnWidth">
            <b-card>
              <b-card-header>{{col.curriculumTitle}}</b-card-header>
              <b-card-body><img :src="col.curriculumImage"/></b-card-body>
              <b-card-footer>{{col.curriculumDesc}}</b-card-footer>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        curriculums: [
          {
            id: 5,
            curriculumGuid: '',
            curriculumTitle: 'Manchester School District',
            curriculumDesc: 'Computer Science Curriculum',
            curriculumImage: '',
          }
        ],
        gridWidth: 4,
      };
    },
    computed: {
      columnWidth() {
        return Math.floor(12 / this.gridWidth);
      },
      curriculumGrid() {
        let result = [];
        let rowArray = [];
        for (let index = 0; index < this.curriculums.length; index++) {
          if (index % this.gridWidth === 0) {
            result.push(rowArray);
            rowArray = [];
          }
          rowArray.push(this.curriculums[index])
        }
        if (rowArray.length > 0) {
          result.push(rowArray);
        }
        return result; 
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>