import axios from 'axios';

const rootUrl = `${process.env.VUE_APP_HIERARCHY_URL}`;
const getUrl = `${rootUrl}/hierarchy`;

const author_key = `${process.env.VUE_APP_HIERARCHY_ID}`;
const author_secret = `${process.env.VUE_APP_HIERARCHY_SECRET}`;

let token = '';
let tokenExpire = '';

export default {
  async doAuth() {
    if (token && tokenExpire > new Date()) {
      return token;
    }
    const url = `${rootUrl}/connect/token`;
    const response = await axios(
      {
        url: url,
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
        data: `grant_type=client_credentials&scope=api&client_id=${author_key}&client_secret=${author_secret}`,
      }
    );
    token = response.data.access_token;
    const dt = new Date();
    tokenExpire = new Date(dt.setMinutes(dt.getMinutes() + (response.data.expires_in / 60) - 5));
  },
  async getHierarchyObjects(curriculumGuid, hierarchyGuid, objectId) {
    await this.doAuth();
    const url = `${getUrl}/${curriculumGuid}/${hierarchyGuid}?objectId=${objectId}`;
    const resposne = await axios(
      {
        url: url,
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      }
    );
    return resposne.data;
  },
  async getHierarchyCDFs(curriculumGuid, hierarchyGuid) {
    await this.doAuth();
    const url = `${getUrl}/${curriculumGuid}/${hierarchyGuid}/CustomDataFields`;
    const resposne = await axios(
      {
        url: url,
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      }
    );
    return resposne.data;
  },
  async getProjectHierarchyCDFs(projectId, hierarchyLvl) {
    await this.doAuth();
    const url = `${getUrl}/CustomDataFields?projectId=${projectId}&hierarchyLvl=${hierarchyLvl}`;
    const resposne = await axios(
      {
        url: url,
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      }
    );
    return resposne.data;
  },
  mapHierarchyObjectsToUIObjects(hierarchyObjects) {
    const resultList = [];

    for (let index = 0; index < hierarchyObjects.length; index++) {
      resultList.push(
        { x:(index * 2),y:0,w:2,h:2,i:index,
          isHidden: false,
          imageUrl: '',
          title: hierarchyObjects[index].resourceName,
          description: '',
          resourceName: hierarchyObjects[index].resourceName,
          resourceType: hierarchyObjects[index].resourceType,
          resourceId: hierarchyObjects[index].resourceId,
          objectId: hierarchyObjects[index].objectId,
          language: hierarchyObjects[index].language,
        }
      );
      
    }
    return resultList;
  },
}